@import "syntax-highlight.scss";

$root-bg: #443627;
$article-color: #dfd6caee;
$accent-site: #ddd;
$accent-nav: #725e54;
$accent-header: black;
$any-er-bg: #00000099;
$body-bg: #443628;
$age-check-bg: #dfd6ca33;
$kudos-bg: #115;
$kudos-glow: #fc8;
$kudos-glow-hover: #fe8;
$blurb-color: #acf;
$marquee-spacer: 8ch;
$shadow-color: black;

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 14pt;
	font-family: "Text Font";
	font-weight: 700;
}

body {
	font-size: 1rem;
	padding: 0;
	margin: 0;
	line-height: 1.5;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
	min-height: 100vh;
	background-image: url("/assets/images/bg1.png");
	background-color: $body-bg;
}

ul.kudos-list {
	list-style: none;
	a {
		text-decoration: none;
	}
	a > li::after {
		content: "\21d7"
	}
	li {
		--glowy: #{$kudos-glow};
		--inner-rad: 0.75em;
		display: inline-block;
		padding: 0 0.5em;
		background: $kudos-bg;
		color: white;
		border-radius: 0.5em;
		border: 1px solid var(--glowy);
		box-shadow: 0 0 0.5em var(--glowy),
			inset 0 0 var(--inner-rad) var(--glowy);
		transition: border .3s, box-shadow .3s;
	}
	li:hover {
		--glowy: #{$kudos-glow-hover};
		--inner-rad: 1em;
	}
}


@font-face {
	font-family: "Header Font";
	src: url("/assets/fonts/Bagnard.woff2");
}


@font-face {
	font-family: "Text Font";
	src: url("/assets/fonts/ResagokrLight.woff2");
}

@font-face {
	font-family: "Title Font";
	src: url("/assets/fonts/learning_curve.woff");
}

@font-face {
	font-family: "Self Aware";
	src: url("/assets/fonts/learning_curve.woff");
}

@font-face {
	font-family: "Cursive";
	src: url("/assets/fonts/learning_curve.woff");
}

@font-face {
	font-family: "Mono Font";
	src: url("/assets/fonts/DejaVuSansMono.woff2");
}

@font-face {
	font-family: "Megrim";
	src: url("/assets/fonts/Megrim.woff2");
}

@font-face {
	font-family: "Noto Symbols";
	src: url("/assets/fonts/NotoSansSymbols2-Regular.woff2");
}

.bagnard { font-family: "Header Font"; }
.megrim { font-family: "Megrim"; }
.resagokr { font-family: "Text Font"; }
.dejavu-sans-mono { font-family: "Mono Font"; }
.learning-curve { font-family: "Title Font"; }
.noto-symbols { font-family: "Noto Symbols"; }


h1, h2, h3, h4, h5, h6 {
	&:before {
		color: $accent-header;
	}
	font-family: "Header Font", Garamond, "Serif";
}

article {
	--side-margin: 2rem;
	font-family: "Text Font";
	display: flex;
	flex-direction: column;
	padding: 1rem;
	width: 55rem;
	max-width: 100vw;
	margin: 1.5rem auto;
	border-radius: .5rem;
	box-shadow: 0 0 4rem 0.5rem $shadow-color;
	flex-grow: 1;

	background: $article-color;
	h1 {
		margin: 0;
		div {
			flex: 0 0 auto;
		}
		display: flex;
		align-items: center;
		font-size: 1.4rem;
		&:before {
			content: "\2042";
			flex: 0 0 auto;
			width: var(--side-margin);
		}
	}
	sup {
		font-family: "Mono Font", monospace;
		font-size: .75rem;
	}
	> ul, > ol {
		> li + li { margin-top: .75rem; }
		li ol, li ul {
			> li + li { margin-top: .25rem; }
		}

	}
	ul ol, ul ul, ol ol, ol ul {
		padding-left: revert-layer;
	}
	> * { margin: 0 var(--side-margin); }
	> * + * { margin-top: .75rem; }
	> * + :is(h2, h3, h4, h5, h6) { margin-top: 1.5rem; }
	> :is(h2, h3, h4, h5, h6) + * { margin-top: .25rem; }
	.blurb::before {
		content: "MattGPT Summary:";
		font-family: "Mono Font";
		font-weight: bold;
	}
	.blurb {
		border-radius: 1em;
		background:
			content-box top right no-repeat url("/assets/images/tldr.svg"),
			linear-gradient($blurb-color, $blurb-color);
		background-size: contain;
		padding: 0.5em 1em;
		>ul, >ol {
			margin-left: 1em;
		}
	}
	div.nest-list ul {
		padding-left: revert-layer;
	}

	h2 { font-size: 1.15rem; }

	h3 { font-size: 1rem; }
}

@media (max-width: 600px) {
	article {
		--side-margin: 0;
		h1::before {
			content: unset;
		}
	}
}

pre, code {
	line-height: 1.25;
	font-size: .7rem;
	font-family: "Mono Font", monospace;
	font-weight: 400;
	border-radius: .45ch;
}

pre {
	background: #1a1039ee;
	padding: 1ch;
	overflow: auto;
}

figure {
	figcaption {
		font-size: .75rem;
	}
	display: flex;
	flex-direction: column;
	gap:.75em;
	background: #ffffff60;
	padding: .5rem;
	border-radius: .5rem;
}


pre.highlight > code {
	color: #bbb;
	padding: 0;
	background: transparent;
}

code {
	background: #cfcfcf;
	padding:  .11ch 0.66ch;
}

navigation {
	display: flex;
	background: linear-gradient($shadow-color, transparent);
	flex-direction: column;
	flex-wrap: nowrap;
	gap: 0.5rem;
	overflow: auto hidden;

	a {
		text-decoration: none;
	}

	#garbage-cycler {
		cursor: pointer;
		background-color: transparent;
		border-radius: 0;
		border: none;
		line-height: unset;
	}

	* {
		flex: 0 0 auto;
	}

	#home-holder, #lesser-navigation {
		display: flex;
		flex-direction: row;
		gap: 0.75rem;
	}

	h2 {
		--anim-duration: .25s;
		font-size: 1.2rem;
		transition: color .25s;
		color: black;
		background-color: $article-color;
		padding: 0.5rem 1rem;
		font-variant: small-caps;
		box-shadow: 0 0 1rem 0.1rem $shadow-color;
		transition: border-radius var(--anim-duration);
		&:before {
			font-family: "Noto Symbols";
			transition: color var(--anim-duration);
			content: "\1F65A ";
			color: $accent-nav;
		}
	}

	h2:hover {
		border-radius: 1em;
		&:before {
			color: black;
		}
	}

	h1#absolute-home {
		color: #ccc;
		transition: color .25s;
		flex: 0 0 auto;

		font-family: "Title Font";
		font-size: 3rem;
		letter-spacing: -.025em;
		&:after {
			content: " \21a9";
			color: $accent-site;
			font-weight: 1;
			font-size: 2rem;
			transition: color 1s;
		}
	}

	h1#absolute-home:hover {
		color: #fff;

		&:after {
			color: #aaf;
		}
	}
}

navigation, footer {
	padding: 1rem;

	color: #eee;
}
footer {
	display: flex;
	flex-direction: column;
	background:linear-gradient(transparent, #080000cc 20%, $shadow-color);
	font-size: .8rem;
	font-weight: 600;
	a:link { color: #ccf; }
	a:visited { color: #caf; }
}



table, th, td {
	padding: 0.5rem;
	border-spacing: 0ch;
	text-align: start;
}

th, td {
	padding: 0.5rem;
}

table {
	background: #def;
}

th {
	background: #cb9;
	color: black;
	font-size: 1.5rem;
	font-variant: small-caps;
	border-spacing:0.5rem;
}

tr:nth-child(2n) {
	background: #ccb;
}

.tag-holder { display: none; }
.tag-holder:target { display: block; }
.tag-title { display: block; }
.cc-license-image {
	border-style: none;
	float:left;
	margin-right: 1ch;
	border-width:0;
}

details  {
	--border-color: black;
	background: #ffeeccaa;
	border: 1px var(--border-color) solid;
	border-radius: 1rem;
	summary + * {
		margin-top: 0.25rem;
	}
	* + * {
		margin-top: 1rem;
	}
	ul, ol {
		margin-left: 1rem;
	}
	&[open] {
		padding: 0.25rem 1rem 0.5rem 1rem;
		> summary {
			border-bottom: 1px var(--border-color) solid;
			padding: 0;
			padding-bottom: 0.25rem;
		}
	}
	> summary {
		padding: 0.25rem 1rem;
	}

}

details.info {
	--border-color: green;
}

details.warning {
	--border-color: orange;
}

li ul, li ol {
	margin-left: 2ch;
}

ul.bsl-tag-list {
	display: flex;
	flex: 2 0 0;
	flex-wrap: wrap;
	padding: 0;
	list-style-type: none;
	gap: 0.25ch 0.5ch;
	line-height: 1;
	justify-content: end;
}

.bsl-tag-link {
	text-decoration: none;
}

.bsl-tag {
	display: inline;
	font-size: .75rem;
	font-variant: small-caps;
	font-family: "Header Font";
	background: #bed6ee;
	border-radius: .125ch;
	padding: .25ch .5ch;
	color: #333;
	transition: background 0.5s, color 0.5s;
	word-break: keep-all;
}


.bsl-tag:hover, .bsl-tag:focus, .section-listing-tag:focus {
	background: Lavender;
	color: #003;
}

span.fun:hover, span.fun:focus {
	color: black;
}

span.self-aware {
	font-family: "Self Aware";
	font-style: italic;
}

span.fun {
	box-shadow: 1rem black;
	background-image: radial-gradient(#cc0, #c00, #c0c, #00c, #0cc, #0c0);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	font-weight: bold;
	transition: color 0.25s;
}

#preview-verify[open] {
	--anim-duration: 4s;
	--filter: opacity(0) blur(0) grayscale(0) brightness(1);
	display: flex;
	flex-direction: column;
	gap: .5rem;

	border: none;
	border-radius: 1ch;
	margin: auto;
	padding : 1rem;
	background: $age-check-bg;

	box-shadow: 0 0 0 100vmax #000000f7;
	transition:
		backdrop-filter var(--anim-duration) ease-in,
		 opacity var(--anim-duration) ease-in;
	opacity: 0;
	backdrop-filter: var(--filter);
	-webkit-backdrop-filter: var(--filter);



	> :not(.show-when-closing) {
		display: none;
	}
	> .show-when-closing {
		display: inherit;
	}

	&.open {
		.responses {
			display: flex;
			flex-direction: column;
			gap: 0.5ch;
			> button {
				border: none;
				background: $root-bg;
				color: white;
				padding: 1ch;
				width: 100%;
			}
		}

		opacity: 1;
		--filter: opacity(1) blur(8px) grayscale(80%) brightness(1.5);

		> :not(.show-when-closing) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		> .show-when-closing {
			display: none;
		}
	}
}

.spacer { flex: 1 5 auto; }
.smallcaps { font-variant: small-caps; }

.marquee {
	overflow: clip;
	display: flex;
	transition: transform .5s linear;
	> .spacer {
		flex: 0 0 auto;
		width: $marquee-spacer;
	}
	> .content {
		flex: 0 0 auto;
		width: 100%;
		min-width: max-content;
		animation: marquee 10s linear infinite;
	}
	&.paused > .content {
		animation-play-state: paused;
	}
	&.stopped {
		transform: translateY(-100%);
	}
}

.now-playing {
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	background: black;
	color: white;
}

@keyframes marquee {
	from { transform: translateX(0); }
	to { transform: translateX(calc(-100% - $marquee-spacer)); }
}

#scroll-to {
	--dims: 3rem;
	--spacing: 1rem;
	--midpoint: calc((var(--dims) + var(--spacing))/2);

	position: fixed;
	color: white;
	background: #8bc;
	border-radius: 3em;
	overflow: clip;
	transition: all .5s;
	line-height: 1;

	bottom: var(--midpoint);
	right: var(--midpoint);
	height: 0;
	width: 0;
	font-size: 0;
	border: 0 solid white;

	&.visible {
		bottom: var(--spacing);
		right: var(--spacing);
		height: var(--dims);
		width: var(--dims);
		font-size: 2rem;
		border: .15ch solid white;
	}
}

.footnotes {
	h2, > ol > li {
		margin-top: 1em;
		> * + * { margin-top: .75rem; }
	}
	border-top: 1px solid black;
}

img.icon {
	width: var(--icon-size);
	height: var(--icon-size);
	border-radius: var(--icon-size);
}

.stream-options {
	--icon-size: 1.2rem;
	--closed-size: calc(1.5 * var(--icon-size));
	--gap-size: .5ch;
	--max-icon-count: 5;
	--anim-duration: .5s;
	display: inline-flex;
	flex-direction: row;
	gap: var(--gap-size);
	padding-right: var(--gap-size);
	border-radius: var(--icon-size);
	height: var(--icon-size);
	background: grey;
	overflow: hidden;
	vertical-align: top;
	box-shadow: inset 0 0 var(--gap-size) 2px black;
	transition: max-width var(--anim-duration);
}

.stream-options > button {
	display: none;
	border: 0;
	width: var(--icon-size);
	height: var(--icon-size);
	border-radius: var(--icon-size);
	background: #cfc;
	color: black;
	vertical-align: top;
	flex: 0 0 auto;
	transition: background var(--anim-duration);
}

.stream-options.open > button {
	background: #555;
}

.stream-options.open {
	max-width: calc((var(--icon-size) + var(--gap-size)) * var(--max-icon-count));
}
.stream-options.closed {
	max-width: var(--closed-size);
}

.stream-options.closed > button,
.stream-options.open > button {
	display: revert-layer;

}

blockquote {
	font-family: "Cursive";
	font-size: 1.5rem;
	padding: 0 2rem;
}
